.root {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 10px;
}
.freeBox__nNBnT {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text {
  width: auto;
  height: auto;
  max-width: 800px;
}
.h3 {
  text-align: center;
  width: auto;
}
.h3isSignUp {
  width: 100%;
  min-width: 0;
}
.supabaseUserLogIn:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 0px;
  min-width: 0;
}
.supabaseUserLogInisSignUp:global(.__wab_instance) {
  display: none;
}
.formContext__vzgCf:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.freeBox__yGwlf {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__yGwlf > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 30px);
  height: calc(100% + 30px);
}
.freeBox__yGwlf > :global(.__wab_flex-container) > *,
.freeBox__yGwlf > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__yGwlf > :global(.__wab_flex-container) > picture > img,
.freeBox__yGwlf
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 30px;
}
.formTextInput__krNe3:global(.__wab_instance) {
  max-width: 100%;
}
.textInput__h9Ubv:global(.__wab_instance) {
  max-width: 100%;
}
.svg__rroec {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg___3Xae {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.formTextInput___6TYjx:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput___4FPex:global(.__wab_instance) {
  max-width: 100%;
}
.svg__cxQn0 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg___5P6Eb {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.button__ojTbh:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__hfbqe {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__ey4Bs {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.supabaseUserSignUp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
  display: none;
}
.supabaseUserSignUpisSignUp:global(.__wab_instance) {
  display: flex;
}
.formContext__m3WPg:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.freeBox__kgdR3 {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__kgdR3 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 30px);
  height: calc(100% + 30px);
}
.freeBox__kgdR3 > :global(.__wab_flex-container) > *,
.freeBox__kgdR3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__kgdR3 > :global(.__wab_flex-container) > picture > img,
.freeBox__kgdR3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 30px;
}
.formTextInput___1TtvC:global(.__wab_instance) {
  max-width: 100%;
}
.textInput___0Rxjw:global(.__wab_instance) {
  max-width: 100%;
}
.svg__vhGxy {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__yCvdz {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.formTextInput__b0Bu5:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput__i9Hze:global(.__wab_instance) {
  max-width: 100%;
}
.svg__kP4QA {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__ycdHj {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.button__gyCft:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__sfHlh {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__kAs7 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.freeBox__stx6O {
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.freeBoxerror__stx6Ojn4Jj {
  display: flex;
}
.slotTargetErrorMessage {
  color: #ff0000;
}
.text___6MEwR {
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  color: #ff0000;
  min-width: 0;
}
.switchSignInOrSignUp2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  cursor: pointer;
  padding: 8px;
}
.link___2NNOm {
  width: auto;
  height: auto;
  max-width: 800px;
  text-align: center;
  cursor: pointer;
}
