@import url("https://fonts.googleapis.com/css2?family=Inter%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Inconsolata%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&display=swap");

.plasmic_tokens {
  --token-ifvFeDqCtjkh: #ff0000;
  --plasmic-token-unnamed-style-token: var(--token-ifvFeDqCtjkh);
}

.plasmic_default_styles {
  --mixin-yvt8Nj3WO5Y1_font-family: "Inter";
  --mixin-yvt8Nj3WO5Y1_font-size: 16px;
  --mixin-yvt8Nj3WO5Y1_font-weight: 400;
  --mixin-yvt8Nj3WO5Y1_font-style: normal;
  --mixin-yvt8Nj3WO5Y1_color: #535353;
  --mixin-yvt8Nj3WO5Y1_text-align: left;
  --mixin-yvt8Nj3WO5Y1_text-transform: none;
  --mixin-yvt8Nj3WO5Y1_line-height: 1.5;
  --mixin-yvt8Nj3WO5Y1_letter-spacing: normal;
  --mixin-yvt8Nj3WO5Y1_white-space: pre-wrap;
  --mixin-yvt8Nj3WO5Y1_user-select: text;
  --mixin-yvt8Nj3WO5Y1_text-decoration-line: none;
  --mixin-yvt8Nj3WO5Y1_text-overflow: clip;
  --mixin-2ZK1QvNnzSiz_color: #000000;
  --mixin-2ZK1QvNnzSiz_font-weight: 900;
  --mixin-2ZK1QvNnzSiz_font-size: 72px;
  --mixin-2ZK1QvNnzSiz_line-height: 1;
  --mixin-2ZK1QvNnzSiz_letter-spacing: -4px;
  --mixin-qfADRztyyZHk_font-family: "Inter";
  --mixin-qfADRztyyZHk_color: #000000;
  --mixin-qfADRztyyZHk_font-size: 48px;
  --mixin-qfADRztyyZHk_font-weight: 700;
  --mixin-qfADRztyyZHk_letter-spacing: -1px;
  --mixin-qfADRztyyZHk_line-height: 1.1;
  --mixin-apeddjL4WVXz_color: #0070f3;
  --mixin-zA-lst2I1vSk_font-family: "Inter";
  --mixin-zA-lst2I1vSk_color: #000000;
  --mixin-zA-lst2I1vSk_font-size: 32px;
  --mixin-zA-lst2I1vSk_font-weight: 600;
  --mixin-zA-lst2I1vSk_letter-spacing: -0.8px;
  --mixin-zA-lst2I1vSk_line-height: 1.2;
  --mixin-iu9VWs6C3BwK_font-family: "Inter";
  --mixin-iu9VWs6C3BwK_color: #000000;
  --mixin-iu9VWs6C3BwK_font-size: 24px;
  --mixin-iu9VWs6C3BwK_font-weight: 600;
  --mixin-iu9VWs6C3BwK_letter-spacing: -0.5px;
  --mixin-iu9VWs6C3BwK_line-height: 1.3;
  --mixin-prIFqxtlYX1p_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-prIFqxtlYX1p_border-bottom-color: #dddddd;
  --mixin-prIFqxtlYX1p_border-bottom-style: solid;
  --mixin-prIFqxtlYX1p_border-bottom-width: 1px;
  --mixin-prIFqxtlYX1p_border-left-color: #dddddd;
  --mixin-prIFqxtlYX1p_border-left-style: solid;
  --mixin-prIFqxtlYX1p_border-left-width: 1px;
  --mixin-prIFqxtlYX1p_border-right-color: #dddddd;
  --mixin-prIFqxtlYX1p_border-right-style: solid;
  --mixin-prIFqxtlYX1p_border-right-width: 1px;
  --mixin-prIFqxtlYX1p_border-top-color: #dddddd;
  --mixin-prIFqxtlYX1p_border-top-style: solid;
  --mixin-prIFqxtlYX1p_border-top-width: 1px;
  --mixin-prIFqxtlYX1p_border-bottom-left-radius: 3px;
  --mixin-prIFqxtlYX1p_border-bottom-right-radius: 3px;
  --mixin-prIFqxtlYX1p_border-top-left-radius: 3px;
  --mixin-prIFqxtlYX1p_border-top-right-radius: 3px;
  --mixin-prIFqxtlYX1p_font-family: "Inconsolata";
  --mixin-prIFqxtlYX1p_padding-bottom: 1px;
  --mixin-prIFqxtlYX1p_padding-left: 4px;
  --mixin-prIFqxtlYX1p_padding-right: 4px;
  --mixin-prIFqxtlYX1p_padding-top: 1px;
  --mixin-sLfBmD9ycty9_border-left-color: #dddddd;
  --mixin-sLfBmD9ycty9_border-left-style: solid;
  --mixin-sLfBmD9ycty9_border-left-width: 3px;
  --mixin-sLfBmD9ycty9_color: #888888;
  --mixin-sLfBmD9ycty9_padding-left: 10px;
  --mixin-Dc9uUek3i4W0_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-Dc9uUek3i4W0_border-bottom-color: #dddddd;
  --mixin-Dc9uUek3i4W0_border-bottom-style: solid;
  --mixin-Dc9uUek3i4W0_border-bottom-width: 1px;
  --mixin-Dc9uUek3i4W0_border-left-color: #dddddd;
  --mixin-Dc9uUek3i4W0_border-left-style: solid;
  --mixin-Dc9uUek3i4W0_border-left-width: 1px;
  --mixin-Dc9uUek3i4W0_border-right-color: #dddddd;
  --mixin-Dc9uUek3i4W0_border-right-style: solid;
  --mixin-Dc9uUek3i4W0_border-right-width: 1px;
  --mixin-Dc9uUek3i4W0_border-top-color: #dddddd;
  --mixin-Dc9uUek3i4W0_border-top-style: solid;
  --mixin-Dc9uUek3i4W0_border-top-width: 1px;
  --mixin-Dc9uUek3i4W0_border-bottom-left-radius: 3px;
  --mixin-Dc9uUek3i4W0_border-bottom-right-radius: 3px;
  --mixin-Dc9uUek3i4W0_border-top-left-radius: 3px;
  --mixin-Dc9uUek3i4W0_border-top-right-radius: 3px;
  --mixin-Dc9uUek3i4W0_font-family: "Inconsolata";
  --mixin-Dc9uUek3i4W0_padding-bottom: 3px;
  --mixin-Dc9uUek3i4W0_padding-left: 6px;
  --mixin-Dc9uUek3i4W0_padding-right: 6px;
  --mixin-Dc9uUek3i4W0_padding-top: 3px;
  --mixin-czmEcyQO1xuI_display: flex;
  --mixin-czmEcyQO1xuI_flex-direction: column;
  --mixin-czmEcyQO1xuI_align-items: stretch;
  --mixin-czmEcyQO1xuI_justify-content: flex-start;
  --mixin-czmEcyQO1xuI_list-style-position: outside;
  --mixin-czmEcyQO1xuI_padding-left: 40px;
  --mixin-czmEcyQO1xuI_position: relative;
  --mixin-czmEcyQO1xuI_list-style-type: disc;
  --mixin-IkH6VCVm6qYW_display: flex;
  --mixin-IkH6VCVm6qYW_flex-direction: column;
  --mixin-IkH6VCVm6qYW_align-items: stretch;
  --mixin-IkH6VCVm6qYW_justify-content: flex-start;
  --mixin-IkH6VCVm6qYW_list-style-position: outside;
  --mixin-IkH6VCVm6qYW_padding-left: 40px;
  --mixin-IkH6VCVm6qYW_position: relative;
  --mixin-IkH6VCVm6qYW_list-style-type: decimal;
  --mixin-Nj2BCBEKCO20_font-family: "Inter";
  --mixin-Nj2BCBEKCO20_color: #000000;
  --mixin-Nj2BCBEKCO20_font-size: 20px;
  --mixin-Nj2BCBEKCO20_font-weight: 600;
  --mixin-Nj2BCBEKCO20_letter-spacing: -0.3px;
  --mixin-Nj2BCBEKCO20_line-height: 1.5;
  --mixin-30VNqsggBxo1_font-family: "Inter";
  --mixin-30VNqsggBxo1_color: #000000;
  --mixin-30VNqsggBxo1_font-size: 16px;
  --mixin-30VNqsggBxo1_font-weight: 600;
  --mixin-30VNqsggBxo1_line-height: 1.5;
  --mixin-d7jtWnOhS7rV_color: #3291ff;
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.img) {
  display: inline-block;
}
:where(.li) {
  display: list-item;
}
:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.address) {
  font-style: inherit;
}
:where(.a) {
  color: inherit;
}
:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.select) {
  padding: 2px 6px;
}
.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.root_reset {
  font-family: var(--mixin-yvt8Nj3WO5Y1_font-family);
  font-size: var(--mixin-yvt8Nj3WO5Y1_font-size);
  font-weight: var(--mixin-yvt8Nj3WO5Y1_font-weight);
  font-style: var(--mixin-yvt8Nj3WO5Y1_font-style);
  color: var(--mixin-yvt8Nj3WO5Y1_color);
  text-align: var(--mixin-yvt8Nj3WO5Y1_text-align);
  text-transform: var(--mixin-yvt8Nj3WO5Y1_text-transform);
  line-height: var(--mixin-yvt8Nj3WO5Y1_line-height);
  letter-spacing: var(--mixin-yvt8Nj3WO5Y1_letter-spacing);
  white-space: var(--mixin-yvt8Nj3WO5Y1_white-space);
}

:where(.root_reset .plasmic_default__h1),
:where(.root_reset .h1),
:where(.root_reset.plasmic_default__h1) {
  color: var(--mixin-2ZK1QvNnzSiz_color);
  font-weight: var(--mixin-2ZK1QvNnzSiz_font-weight);
  font-size: var(--mixin-2ZK1QvNnzSiz_font-size);
  line-height: var(--mixin-2ZK1QvNnzSiz_line-height);
  letter-spacing: var(--mixin-2ZK1QvNnzSiz_letter-spacing);
}

:where(.root_reset .plasmic_default__h2),
:where(.root_reset .h2),
:where(.root_reset.plasmic_default__h2) {
  font-family: var(--mixin-qfADRztyyZHk_font-family);
  color: var(--mixin-qfADRztyyZHk_color);
  font-size: var(--mixin-qfADRztyyZHk_font-size);
  font-weight: var(--mixin-qfADRztyyZHk_font-weight);
  letter-spacing: var(--mixin-qfADRztyyZHk_letter-spacing);
  line-height: var(--mixin-qfADRztyyZHk_line-height);
}

:where(.root_reset .plasmic_default__a),
:where(.root_reset .a),
:where(.root_reset.plasmic_default__a) {
  color: var(--mixin-apeddjL4WVXz_color);
}

:where(.root_reset .plasmic_default__h3),
:where(.root_reset .h3),
:where(.root_reset.plasmic_default__h3) {
  font-family: var(--mixin-zA-lst2I1vSk_font-family);
  color: var(--mixin-zA-lst2I1vSk_color);
  font-size: var(--mixin-zA-lst2I1vSk_font-size);
  font-weight: var(--mixin-zA-lst2I1vSk_font-weight);
  letter-spacing: var(--mixin-zA-lst2I1vSk_letter-spacing);
  line-height: var(--mixin-zA-lst2I1vSk_line-height);
}

:where(.root_reset .plasmic_default__h4),
:where(.root_reset .h4),
:where(.root_reset.plasmic_default__h4) {
  font-family: var(--mixin-iu9VWs6C3BwK_font-family);
  color: var(--mixin-iu9VWs6C3BwK_color);
  font-size: var(--mixin-iu9VWs6C3BwK_font-size);
  font-weight: var(--mixin-iu9VWs6C3BwK_font-weight);
  letter-spacing: var(--mixin-iu9VWs6C3BwK_letter-spacing);
  line-height: var(--mixin-iu9VWs6C3BwK_line-height);
}

:where(.root_reset .plasmic_default__code),
:where(.root_reset .code),
:where(.root_reset.plasmic_default__code) {
  background: var(--mixin-prIFqxtlYX1p_background);
  font-family: var(--mixin-prIFqxtlYX1p_font-family);
  border-radius: var(--mixin-prIFqxtlYX1p_border-top-left-radius)
    var(--mixin-prIFqxtlYX1p_border-top-right-radius)
    var(--mixin-prIFqxtlYX1p_border-bottom-right-radius)
    var(--mixin-prIFqxtlYX1p_border-bottom-left-radius);
  padding: var(--mixin-prIFqxtlYX1p_padding-top)
    var(--mixin-prIFqxtlYX1p_padding-right)
    var(--mixin-prIFqxtlYX1p_padding-bottom)
    var(--mixin-prIFqxtlYX1p_padding-left);
  border-top: var(--mixin-prIFqxtlYX1p_border-top-width)
    var(--mixin-prIFqxtlYX1p_border-top-style)
    var(--mixin-prIFqxtlYX1p_border-top-color);
  border-right: var(--mixin-prIFqxtlYX1p_border-right-width)
    var(--mixin-prIFqxtlYX1p_border-right-style)
    var(--mixin-prIFqxtlYX1p_border-right-color);
  border-bottom: var(--mixin-prIFqxtlYX1p_border-bottom-width)
    var(--mixin-prIFqxtlYX1p_border-bottom-style)
    var(--mixin-prIFqxtlYX1p_border-bottom-color);
  border-left: var(--mixin-prIFqxtlYX1p_border-left-width)
    var(--mixin-prIFqxtlYX1p_border-left-style)
    var(--mixin-prIFqxtlYX1p_border-left-color);
}

:where(.root_reset .plasmic_default__blockquote),
:where(.root_reset .blockquote),
:where(.root_reset.plasmic_default__blockquote) {
  color: var(--mixin-sLfBmD9ycty9_color);
  padding-left: var(--mixin-sLfBmD9ycty9_padding-left);
  border-left: var(--mixin-sLfBmD9ycty9_border-left-width)
    var(--mixin-sLfBmD9ycty9_border-left-style)
    var(--mixin-sLfBmD9ycty9_border-left-color);
}

:where(.root_reset .plasmic_default__pre),
:where(.root_reset .pre),
:where(.root_reset.plasmic_default__pre) {
  background: var(--mixin-Dc9uUek3i4W0_background);
  font-family: var(--mixin-Dc9uUek3i4W0_font-family);
  border-radius: var(--mixin-Dc9uUek3i4W0_border-top-left-radius)
    var(--mixin-Dc9uUek3i4W0_border-top-right-radius)
    var(--mixin-Dc9uUek3i4W0_border-bottom-right-radius)
    var(--mixin-Dc9uUek3i4W0_border-bottom-left-radius);
  padding: var(--mixin-Dc9uUek3i4W0_padding-top)
    var(--mixin-Dc9uUek3i4W0_padding-right)
    var(--mixin-Dc9uUek3i4W0_padding-bottom)
    var(--mixin-Dc9uUek3i4W0_padding-left);
  border-top: var(--mixin-Dc9uUek3i4W0_border-top-width)
    var(--mixin-Dc9uUek3i4W0_border-top-style)
    var(--mixin-Dc9uUek3i4W0_border-top-color);
  border-right: var(--mixin-Dc9uUek3i4W0_border-right-width)
    var(--mixin-Dc9uUek3i4W0_border-right-style)
    var(--mixin-Dc9uUek3i4W0_border-right-color);
  border-bottom: var(--mixin-Dc9uUek3i4W0_border-bottom-width)
    var(--mixin-Dc9uUek3i4W0_border-bottom-style)
    var(--mixin-Dc9uUek3i4W0_border-bottom-color);
  border-left: var(--mixin-Dc9uUek3i4W0_border-left-width)
    var(--mixin-Dc9uUek3i4W0_border-left-style)
    var(--mixin-Dc9uUek3i4W0_border-left-color);
}

:where(.root_reset .plasmic_default__ul),
:where(.root_reset .ul),
:where(.root_reset.plasmic_default__ul) {
  display: var(--mixin-czmEcyQO1xuI_display);
  flex-direction: var(--mixin-czmEcyQO1xuI_flex-direction);
  align-items: var(--mixin-czmEcyQO1xuI_align-items);
  justify-content: var(--mixin-czmEcyQO1xuI_justify-content);
  list-style-position: var(--mixin-czmEcyQO1xuI_list-style-position);
  padding-left: var(--mixin-czmEcyQO1xuI_padding-left);
  position: var(--mixin-czmEcyQO1xuI_position);
  list-style-type: var(--mixin-czmEcyQO1xuI_list-style-type);
  flex-column-gap: var(--mixin-czmEcyQO1xuI_flex-column-gap);
}

:where(.root_reset .plasmic_default__ol),
:where(.root_reset .ol),
:where(.root_reset.plasmic_default__ol) {
  display: var(--mixin-IkH6VCVm6qYW_display);
  flex-direction: var(--mixin-IkH6VCVm6qYW_flex-direction);
  align-items: var(--mixin-IkH6VCVm6qYW_align-items);
  justify-content: var(--mixin-IkH6VCVm6qYW_justify-content);
  list-style-position: var(--mixin-IkH6VCVm6qYW_list-style-position);
  padding-left: var(--mixin-IkH6VCVm6qYW_padding-left);
  position: var(--mixin-IkH6VCVm6qYW_position);
  list-style-type: var(--mixin-IkH6VCVm6qYW_list-style-type);
  flex-column-gap: var(--mixin-IkH6VCVm6qYW_flex-column-gap);
}

:where(.root_reset .plasmic_default__h5),
:where(.root_reset .h5),
:where(.root_reset.plasmic_default__h5) {
  font-family: var(--mixin-Nj2BCBEKCO20_font-family);
  color: var(--mixin-Nj2BCBEKCO20_color);
  font-size: var(--mixin-Nj2BCBEKCO20_font-size);
  font-weight: var(--mixin-Nj2BCBEKCO20_font-weight);
  letter-spacing: var(--mixin-Nj2BCBEKCO20_letter-spacing);
  line-height: var(--mixin-Nj2BCBEKCO20_line-height);
}

:where(.root_reset .plasmic_default__h6),
:where(.root_reset .h6),
:where(.root_reset.plasmic_default__h6) {
  font-family: var(--mixin-30VNqsggBxo1_font-family);
  color: var(--mixin-30VNqsggBxo1_color);
  font-size: var(--mixin-30VNqsggBxo1_font-size);
  font-weight: var(--mixin-30VNqsggBxo1_font-weight);
  line-height: var(--mixin-30VNqsggBxo1_line-height);
}

:where(.root_reset .plasmic_default__a:hover),
:where(.root_reset .a:hover),
:where(.root_reset.plasmic_default__a:hover) {
  color: var(--mixin-d7jtWnOhS7rV_color);
}
