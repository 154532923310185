.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.freeBox__zt42 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__lt20I {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: auto;
  max-width: 100%;
  padding: 8px;
}
.freeBox__i4Gkg {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text__iXgC {
  width: auto;
  height: auto;
  max-width: 800px;
}
.h3 {
  text-align: center;
}
.supabaseMutation:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 80%;
}
.formContext:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.freeBox__bcVVs {
  flex-direction: column;
  display: flex;
  width: auto;
  height: 100%;
  max-width: 100%;
  min-height: 0;
  padding: 8px;
}
.freeBox__bcVVs > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 0;
  margin-top: calc(0px - 30px);
  height: calc(100% + 30px);
}
.freeBox__bcVVs > :global(.__wab_flex-container) > *,
.freeBox__bcVVs > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__bcVVs > :global(.__wab_flex-container) > picture > img,
.freeBox__bcVVs
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 30px;
}
.freeBox___6IErz {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox___6IErz > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
}
.freeBox___6IErz > :global(.__wab_flex-container) > *,
.freeBox___6IErz > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___6IErz > :global(.__wab_flex-container) > picture > img,
.freeBox___6IErz
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
}
.text__auSia {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
}
.formTextInput__idzUu:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput__h9LE:global(.__wab_instance) {
  max-width: 100%;
  width: 80%;
}
.svg__sL17 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__nhwPc {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.freeBox__d2GK {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__d2GK > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
}
.freeBox__d2GK > :global(.__wab_flex-container) > *,
.freeBox__d2GK > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__d2GK > :global(.__wab_flex-container) > picture > img,
.freeBox__d2GK
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
}
.text___6Llo0 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
}
.formTextInput__icFcq:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput___0AFB:global(.__wab_instance) {
  max-width: 100%;
  width: 80%;
}
.svg___6Jhjj {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__dg71 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.freeBox__nn7V8 {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__nn7V8 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
}
.freeBox__nn7V8 > :global(.__wab_flex-container) > *,
.freeBox__nn7V8 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__nn7V8 > :global(.__wab_flex-container) > picture > img,
.freeBox__nn7V8
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
}
.text__widud {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
}
.formTextInput___1YEP:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textarea {
  width: 80%;
  height: 200px;
  max-width: 100%;
}
.button:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__oT56Q {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__he4E0 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
