.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.freeBox___4VeU {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__osmuF {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: auto;
  max-width: 100%;
  padding: 8px;
}
.freeBox___8RIm4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text__xBvE {
  width: auto;
  height: auto;
  max-width: 800px;
}
.h3 {
  text-align: center;
}
.supabaseQuery:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.supabaseMutation:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__afJqs {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.formContext:global(.__wab_instance) {
  max-width: 100%;
  width: 80%;
}
.freeBox__cymKw {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__aIc06 {
  flex-direction: row;
  display: flex;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__aIc06 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox__aIc06 > :global(.__wab_flex-container) > *,
.freeBox__aIc06 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__aIc06 > :global(.__wab_flex-container) > picture > img,
.freeBox__aIc06
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.text__fZjN3 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
}
.formTextInput__oph2O:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput___24NcN:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 85%;
}
.svg___3TSR {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__w1VhS {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.freeBox__jnrQa {
  flex-direction: row;
  display: flex;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__jnrQa > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox__jnrQa > :global(.__wab_flex-container) > *,
.freeBox__jnrQa > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__jnrQa > :global(.__wab_flex-container) > picture > img,
.freeBox__jnrQa
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.text__fhive {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
}
.formTextInput__mcOBl:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textInput__qSat:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 85%;
}
.svg__bxsxz {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__xz0Tf {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.freeBox__y1My {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__y1My > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox__y1My > :global(.__wab_flex-container) > *,
.freeBox__y1My > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__y1My > :global(.__wab_flex-container) > picture > img,
.freeBox__y1My
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.text__eYgtw {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  padding-left: 0px;
}
.formTextInput__dYq7O:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.textarea {
  position: relative;
  width: 85%;
  height: 200px;
  max-width: 100%;
  padding: 8px;
}
.freeBox__hqfLt {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.button:global(.__wab_instance) {
  max-width: 100%;
  width: auto;
}
.svg__tta4M {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text__hZJC {
  height: auto;
}
.svg___1YZGx {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
