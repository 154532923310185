.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.supabaseQuery:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.supabaseGridCollection:global(.__wab_instance) {
  max-width: 100%;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.supabaseTextField__s3XRm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.supabaseTextField__ll8Aw:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.supabaseImgField:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100px;
  height: 100px;
  flex-shrink: 0;
}
.supabaseEditButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__hxsC8 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 1em;
  height: 1em;
}
.supabaseDeleteButton__quRk6:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__nUjrx {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 1em;
  height: 1em;
}
.supabaseTextField__vm3Ey:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.img__aM2Cz {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__aM2Cz > picture > img {
  object-fit: cover;
}
.img__aM2Cz > .__wab_img-spacer > img {
  object-fit: cover;
}
.img__l560Z {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img__l560Z > picture > img {
  object-fit: cover;
}
.img__l560Z > .__wab_img-spacer > img {
  object-fit: cover;
}
.supabaseTableCollection:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__gArBx {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 1em;
  height: 1em;
}
.supabaseDeleteButton__a3Ev:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__e6MMk {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 1em;
  height: 1em;
}
.supabaseTextField__o0G9L:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
