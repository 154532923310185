.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.loginComponent:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 80%;
}
.text__hqbIr {
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  color: #ff0000;
  min-width: 0;
}
.link {
  width: auto;
  height: auto;
  max-width: 800px;
  text-align: center;
  cursor: pointer;
}
